<template>
  <section>
    <section
      v-if="noResult === true"
      class="noResult"
    >
      <img
        src="../../assets/icons/fail.svg"
        alt="fail"
      >
      <span>Sorry, No Result Found</span>
    </section>
    <section v-if="noResult === false">
      <section class="content">
        <VCard class="msg_main_card shadow">
          <div class="grid_4">
            <div class="grid_span_3">
              <p class="msg_subtitle">
                Title
              </p>
              <p class="msg_main_txt">
                {{ smsData.title }}
              </p>
            </div>
            <div>
              <p class="msg_subtitle">
                Date
              </p>
              <p class="msg_main_txt">
                {{ smsData.date }}
              </p>
            </div>
          </div>
          <div class="msg_container mt-4">
            <p class="msg_subtitle">
              Message
            </p>
            <p class="msg_main_txt">
              {{ smsData.message }}
            </p>
          </div>
          <div class="mt-5">
            <p class="msg_subtitle">
              Type
            </p>
            <p class="msg_main_txt">
              {{ smsData.type }} SMS
            </p>
          </div>
          <div class="mt-2">
            <p class="msg_subtitle">
              Phone Number
            </p>
            <div class="grid_chip">
              <p
                v-for="phNo in smsData.number"
                :key="phNo"
                class="ma-0"
              >
                {{ phNo }}
              </p>
            </div>
          </div>
        </VCard>
      </section>
    </section>
    <div>
      <VDialog
        v-model="isLoading"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
      >
        <VCard
          v-if="loadStatus === 'loading'"
          class="notification_fs elevation-20"
        >
          <p>Loading</p>
          <span>Fetching Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="white"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_fs elevation-20"
        >
          <p>Internet Issue</p>
          <span>Please Try Again Later</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_fs elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    noResult: false,
    isLoading: true,
    loadStatus: 'loading',
    smsData: '',
  }),
  async beforeMount() {
    try {
      const { id } = this.$route.params;
      await axios.get(`${api}/sms/${id}`)
        .then((res) => {
          if (res.data.status === 'success') {
            this.smsData = res.data.data;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.noResult = true;
          }
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push('/sms');
        }, 5000);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Message/view';
</style>
